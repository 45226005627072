import moment from "moment/moment";
import { GoLocation, GoCalendar } from "react-icons/go";
import { FaPrint } from "react-icons/fa";
import { Link } from "react-router-dom";

export function WeightItem({ tx }) {
  const dateTrial = moment(tx.createdAt).format("MMMM Do YYYY, h:mm:ss a");
  return (
    <div className="flex mx-auto bg-gray-100 flex-col space-y-4 md:min-w-[800px]  items-start text-darkBlue text-lg font-medium  p-8 font-opensans w-full  rounded-lg  shadow-sm ">
      <h3
        className="text-2xl font-bold text-darkBlue1
      ">
        {`${tx.weight} Kg of ${tx.type}  ticket no ${tx.balerNumber}-${tx._id}`}
      </h3>
      <div className="flex flex-col  space-y-4 items-start md:flex-row md:space-x-28 md:justify-between md:items-center md:space-y-0">
        <div className="flex space-x-4 items-center">
          <GoLocation />
          <p>{tx.farmLocation}</p>
        </div>

        <div className="flex space-x-4 items-center">
          <GoCalendar />
          <p>{dateTrial}</p>
        </div>

        <Link
          to={`downloadqr?id=${tx._id}&weight=${tx.weight}&pit=${tx.pit}&farmLocation=${tx.farmLocation}&type=${tx.type}&createdAt=${tx.createdAt}&balerNo=${tx.balerNumber}`}>
          <FaPrint
            size={30}
            className="text-darkBlue bg-white hover:scale-95 hover:bg-darkBlue
           hover:text-white"
          />
        </Link>
      </div>

      <div></div>
    </div>
  );
}
