import {
  Form,
  useActionData,
  useNavigation,
  useSearchParams,
} from "react-router-dom";
import useInput from "../../hooks/use-input";
import { validateEmail, validatePassword } from "../../utils/validators";
import { baseUrl } from "../../constants";
import { useContext, useState } from "react";
import { authContext } from "../../context/auth";

export function AddUser() {
  const auth = useContext(authContext);
  let [searchParams, setSearchParams] = useSearchParams();

  const email = searchParams.get("email");

  const name = searchParams.get("name");

  const role = searchParams.get("role");
  const id = searchParams.get("id");

  const actionData = useActionData();
  console.log(useActionData);

  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  let bgColor = " bg-darkBlue1";
  if (isSubmitting) {
    bgColor = "bg-darkBlue1 opacity-60";
  }

  const btCaption = isSubmitting ? "Submitting..." : "Submit";

  const {
    hasError: emailHasError,
    inputBlurHandler: emailBlurHandler,
    valueChangeHandler: emailChangeHandler,
    value: enteredEmail,
    reset: resetEmail,
    inputValid: emailInputValid,
    valueIsValid: emailIsValid,
  } = useInput((value) => validateEmail(value), email ?? "");

  const [enteredRole, setRole] = useState(role);
  const {
    hasError: nameHasError,
    inputBlurHandler: nameBlurHandler,
    valueChangeHandler: nameChangeHandler,
    value: enteredName,
    reset: resetName,
    inputValid: nameInputValid,
    valueIsValid: nameIsValid,
  } = useInput((value) => value && value.length >= 4, name ?? "");
  const {
    hasError: passwordHasError,
    inputBlurHandler: passwordBlur,
    valueChangeHandler: passwordChangeHandler,
    value: enteredPassword,
    reset: resetPassword,
    inputValid: passwordInputValid,
    valueIsValid: passwordIsValid,
  } = useInput((value) => validatePassword(value), "");

  let emailValidationClass = "border-gray-300 ";
  if (emailHasError) {
    emailValidationClass = "border-red-500 bg-red-100 placeholder:text-red-500";
  }

  let passwordValidationClass = "border-gray-300";
  if (passwordHasError) {
    passwordValidationClass =
      "border-red-500 bg-red-100 placeholder:text-red-500";
  }

  let nameValidationClass = "border-gray-300";
  if (nameHasError) {
    nameValidationClass = "border-red-500 bg-red-100 placeholder:text-red-500";
  }

  return (
    <Form
      className=" flex flex-col pb-8 mx-auto justify-center items-center text-center md:mt-10  md:min-w-[400px]  space-y-10 bg-white  md:flex-row md:space-y-0  "
      method={id ? "PATCH" : "POST"}>
      <div className="p-6 md:p-20">
        <h2 className="font-mono mb-5 text-4xl font-bold">Add User</h2>
        <p className="max-w-sm mb-3 font-sans font-light text-gray-600">
          {id ? `Edit ${name}` : "Create an operator"}
        </p>

        {actionData && (
          <p className="mb-3 text-red-500 ">{actionData.message}</p>
        )}

        <div
          className="flex flex-col space-y-4 md:space-y-10 min-w-52']
        ">
          <input
            value={enteredName}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            type="text"
            name="name"
            required
            className={`w-full p-6 border  rounded-md placeholder:font-sans
             placeholder:font-light h-8 ${nameValidationClass} md:min-w-[450px] `}
            placeholder="Enter name"
          />
          <input
            value={enteredEmail}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            type="email"
            name="email"
            required
            className={`w-full p-6 border md:min-w-[450px] rounded-md placeholder:font-sans
             placeholder:font-light h-8 ${emailValidationClass} `}
            placeholder="Enter email address"
          />

          <input
            onChange={passwordChangeHandler}
            onBlur={passwordBlur}
            value={enteredPassword}
            type="password"
            name="password"
            className={`w-full p-6 border md:min-w-[450px] border-gray-300 rounded-md placeholder:font-sans 
            placeholder:font-light h-8 ${passwordValidationClass}`}
            placeholder="Enter your password"
          />

          <select
            onChange={(event) => setRole(event.target.value)}
            value={enteredRole}
            id="role"
            name="role"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option value={"operator"}>Operator</option>
            <option value={"user"}>User</option>
            <option value={"admin"}>Admin</option>
          </select>
        </div>

        <div className="flex flex-col items-center justify-between mt-8 ">
          <button
            className={`w-full  flex justify-center dark:bg-white dark:text-darkBlue1 items-center py-2 px-2  font-sans font-bold text-white rounded-md text-lg md:text-3xl
            shadow-lg ${bgColor} shadow-cyan-100 hover:bg-opacity-90 shaodw-sm hover:shadow-lg border transition hover:-translate-y-0.5 duration-150`}>
            {btCaption}
          </button>
        </div>
      </div>
    </Form>
  );
}
