import moment, { now } from "moment";
import { QrCodeGen } from "../components/print/QrCode";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { QrPdfGen } from "../components/print/Pdf";
import { useSearchParams } from "react-router-dom";

export function PrintQrPage({ tx }) {
  let [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");
  const weight = searchParams.get("weight");
  const pit = searchParams.get("pit");
  const type = searchParams.get("type");

  const createdAt = searchParams.get("createdAt");

  const balerNumber = searchParams.get("balerNo");
  const location = searchParams.get("farmLocation");

  const Date = moment(createdAt).format("YYYY-MM-55 h:m");

  const fileName = `qrcode-${now()}`;
  return (
    <div className="text-center flex  flex-col justify-center items-center my-auto md:h-[700px] space-y-6">
      <div className=" " key={id}>
        <QrCodeGen
          baler={balerNumber}
          date={Date}
          id={id}
          location={location}
          pit={pit}
          type={type}
          weight={weight}
        />
      </div>
      <PDFDownloadLink
        document={
          <QrPdfGen
            balerId={balerNumber}
            id={id}
            location={location}
            pit={pit}
            type={type}
            weight={weight}
          />
        }
        fileName={`${fileName}.pdf`}>
        {({ loading }) =>
          loading ? (
            "Loading"
          ) : (
            <button className="bg-green-600 text-green-100  text-center  rounded-lg w-48 px-4 py-2 font-bold hover:bg-green-100 hover:text-green-600">
              Download
            </button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
}
