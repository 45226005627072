import { Link, useLoaderData } from "react-router-dom";
import { UserItem } from "../components/users/UserItem";
import { baseUrl } from "../constants";

import { CgAdd } from "react-icons/cg";
import { UserTable } from "../components/users/UserTable";
import { FaPen } from "react-icons/fa";

export function UserPage() {
  const result = useLoaderData();
  let users = [];
  if (result) {
    users = result.users;
  }

  return (
    <div className="flex flex-col md:mt-36 space-y-4  md:min-h-[700px]">
      <div className=" flex justify-end">
        <Link
          to={"/user/add"}
          type="button"
          className="text-green-600   focus:ring-4 focus:outline-none focus:ring-green-600 
          hover:text-green-800 
          font-bold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center ">
          Create user
          <svg
            aria-hidden="true"
            className="w-5 h-5 ml-2 -mr-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </Link>
      </div>

      <div className="flex items-center flex-col space-y-4 justify-center md:hidden">
        {users.map((user) => (
          <UserItem user={user} key={user._id} />
        ))}
      </div>
      <div className="hidden md:block">
        <UserTable users={users} />
      </div>
    </div>
  );
}

export async function loader() {
  const response = await fetch(`${baseUrl}auth/users`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
  }

  return response;
}
