import { useNavigate, useNavigation } from "react-router-dom";
import useInput from "../../hooks/use-input";
import { validateEmail, validatePassword } from "../../utils/validators";
import { baseUrl } from "../../constants";
import { useContext, useState } from "react";
import { authContext } from "../../context/auth";
import moment from "moment";

export default function Login() {
  const navigate = useNavigate();
  const auth = useContext(authContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();

  let bgColor = " bg-darkBlue1";
  if (isSubmitting) {
    bgColor = "bg-darkBlue1 opacity-60";
  }

  const btCaption = isSubmitting ? "Submitting..." : "Login";

  const {
    hasError: emailHasError,
    inputBlurHandler: emailBlurHandler,
    valueChangeHandler: emailChangeHandler,
    value: enteredEmail,

    valueIsValid: emailIsValid,
  } = useInput((value) => validateEmail(value), "");
  const {
    hasError: passwordHasError,
    inputBlurHandler: passwordBlur,
    valueChangeHandler: passwordChangeHandler,
    value: enteredPassword,

    valueIsValid: passwordIsValid,
  } = useInput((value) => validatePassword(value), "");

  let emailValidationClass = "border-gray-300 ";
  if (emailHasError) {
    emailValidationClass = "border-red-500 bg-red-100 placeholder:text-red-500";
  }

  let passwordValidationClass = "border-gray-300";
  if (passwordHasError) {
    passwordValidationClass =
      "border-red-500 bg-red-100 placeholder:text-red-500";
  }

  const submitHandler = async (e) => {
    const formIsValid = emailIsValid && passwordIsValid;
    if (!formIsValid) {
      return;
    }
    e.preventDefault();

    try {
      setIsSubmitting(true);
      const response = await fetch(`${baseUrl}auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },

        body: JSON.stringify({
          password: enteredPassword,
          email: enteredEmail,
        }),
      });
      setIsSubmitting(false);
      if (response.status == 401) {
        const result = await response.json();
        setError(result["message"]);

        return;
      }

      const result = await response.json();
      auth.login(result["token"], {
        name: result["user"].name,
        id: result["user"]._id,
        role: result["user"].role,
        email: result["user"].email,
      });
      console.log(result["user"]);

      localStorage.setItem("token", result["token"]);
      localStorage.setItem("userName", result["user"].name);
      localStorage.setItem("userId", result["user"]._id);
      localStorage.setItem("role", result["user"].role);
      localStorage.setItem("email", result["user"].email);
      const today = moment(new Date()).add(2, "hours");

      localStorage.setItem("expiresIn", today.toDate());
      navigate("/");
    } catch (e) {
      setIsSubmitting(false);
    }
  };
  return (
    <form
      className=" flex flex-col  mx-auto justify-center items-center 
       md:mt-10   space-y-10 bg-white  md:flex-row md:space-y-0 md:min-h-[700px] text-center"
      onSubmit={(event) => submitHandler(event)}>
      <div className="p-6 md:p-20">
        <h2 className="font-mono mb-5 text-4xl font-bold">Log In</h2>
        <p className="max-w-sm mb-5 font-sans font-light text-gray-600">
          Login to start managing your bales weights and transactions
        </p>

        {error && <p className="mb-3 text-red-500 ">{error}</p>}

        <div
          className="flex flex-col space-y-4 md:space-y-10 min-w-52']
        ">
          <input
            value={enteredEmail}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            type="email"
            required
            className={`w-full p-6 border  rounded-md placeholder:font-sans 
            placeholder:font-light h-8 ${emailValidationClass} `}
            placeholder="Enter your email address"
          />

          <input
            onChange={passwordChangeHandler}
            onBlur={passwordBlur}
            value={enteredPassword}
            type="password"
            className={`w-full p-6 border border-gray-300 rounded-md 
            placeholder:font-sans placeholder:font-light h-8 ${passwordValidationClass}`}
            placeholder="Enter your password"
          />
        </div>

        <div className="flex flex-col items-center justify-between mt-8 ">
          <button
            className={`w-full  flex justify-center dark:bg-white dark:text-darkBlue1 items-center py-2 px-2  font-sans font-bold text-white rounded-md text-lg md:text-3xl
            shadow-lg ${bgColor} shadow-cyan-100 hover:bg-opacity-90 shaodw-sm hover:shadow-lg border transition hover:-translate-y-0.5 duration-150`}>
            {btCaption}
          </button>
        </div>
      </div>
    </form>
  );
}
