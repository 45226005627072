import { redirect } from "react-router-dom";
import Login from "../components/auth/Login";
import { baseUrl } from "../constants";

export function LoginPage() {
  return <Login />;
}

export async function action({ request, params }) {
  const data = await request.formData();
  const password = data.get("password");
  const email = data.get("email");
  const response = await fetch(`${baseUrl}auth/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },

    body: JSON.stringify({ password, email }),
  });
  if (response.status === 401) {
    return response;
  }

  const result = await response.json();

  localStorage.setItem("token", result["token"]);
  localStorage.setItem("userName", result["user"].name);
  localStorage.setItem("userId", result["user"]._id);
  localStorage.setItem("role", result["user"].role);

  return redirect("/");
}
