import QrCode from "qrcode.react";
import Barcode from "react-barcode";
export function QrCodeGen({ pit, weight, location, date, id, baler, type }) {
  return (
    <QrCode
      imageSettings={{ height: 20, width: 20 }}
      value={`balerNo:${baler},pit:${pit},weight:${weight},date:${date},location:${location},item:${type},tranId:${id}`}
      id={id}
      size={128}
      bgColor="#ffffff"
      fgColor="#000000"
      level="L"
      includeMargin={true}
    />
  );
}
