import { Link, NavLink, useNavigate } from "react-router-dom";
import { authContext } from "../../context/auth";
import { useContext } from "react";
import { Dropdown } from "flowbite-react";
import { FaCog, FaSignOutAlt, FaUser } from "react-icons/fa";

export default function HeaderComp() {
  const navigate = useNavigate();
  const auth = useContext(authContext);
  const role = auth.user ? auth.user.role : "";
  const token = auth && auth.token;

  const userName = auth.user ? auth.user.name : "";
  const email = auth.user ? auth.user.email : "";

  return (
    <header className=" shadow-lg w-full   mb-10 pb-10 md:pb-0    px-6  z-30 h-40 md:h-20 flex flex-col  justify-center items-center md:absolute top-0 md:flex-row md:justify-between ">
      <div className=" h-20 w-60 mx-auto md:mx-0  flex justify-center items-center">
        <img src={"/images/weight.png"} className="h-10" />

        <Link to={"/"} className="text-3xl text-bold  ">
          <h1 className="text-3xl font-bold font-sans">Kilosahihi</h1>
        </Link>
      </div>

      <ul className="flex space-x-4 mt-4 md:mt-0  items-center   md:space-x-10    md:space-y-0  justify-center ">
        {auth.token && (
          <div className="md:mr-10">
            <Dropdown label={userName} color={"green"} className="md:mr-10">
              <Dropdown.Header>{email}</Dropdown.Header>
              <Dropdown.Item icon={FaCog}>Settings</Dropdown.Item>
              {role === "admin" && (
                <Dropdown.Item icon={FaUser}>
                  <NavLink
                    to="users"
                    className={({ isActive }) =>
                      isActive ? "text-accentCyan " : ""
                    }>
                    Users
                  </NavLink>
                </Dropdown.Item>
              )}

              <Dropdown.Divider />
              <Dropdown.Item
                icon={FaSignOutAlt}
                onClick={() => {
                  auth.logout();
                  navigate("/");
                }}>
                Sign out
              </Dropdown.Item>
            </Dropdown>
          </div>
        )}
      </ul>
    </header>
  );
}
