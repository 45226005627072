import { Table } from "flowbite-react";
import moment from "moment/moment";
import { FaPrint, FaQrcode } from "react-icons/fa";
import { Link } from "react-router-dom";

export function TxTable({ trans }) {
  return (
    <Table>
      <Table.Head>
        <Table.HeadCell>Date</Table.HeadCell>

        <Table.HeadCell>TranId</Table.HeadCell>
        <Table.HeadCell>Tare wt(Kg)</Table.HeadCell>
        <Table.HeadCell>Gross wt(Kg)</Table.HeadCell>

        <Table.HeadCell>Net wt(Kg)</Table.HeadCell>
        <Table.HeadCell>Pit</Table.HeadCell>
        <Table.HeadCell>Location</Table.HeadCell>
        <Table.HeadCell>Created by</Table.HeadCell>
        <Table.HeadCell>Baler Id</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>

        <Table.HeadCell>
          <span className="sr-only">QrCode</span>
        </Table.HeadCell>
      </Table.Head>

      <Table.Body className="divide-y">
        {trans.map((tran) => {
          const date = moment(tran.createdAt).format("DD-MM-YYYY h:m");

          return (
            <Table.Row
              key={tran._id}
              className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>{date}</Table.Cell>
              <Table.Cell>{tran._id}</Table.Cell>
              <Table.Cell>{tran.tare}</Table.Cell>
              <Table.Cell>{tran.gross}</Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {tran.weight.toFixed(1)}
              </Table.Cell>
              <Table.Cell>{tran.pit}</Table.Cell>
              <Table.Cell>{tran.farmLocation}</Table.Cell>
              <Table.Cell>{tran.clerk.name}</Table.Cell>
              <Table.Cell>{tran.balerNumber}</Table.Cell>
              <Table.Cell>{tran.status}</Table.Cell>

              <Table.Cell>
                <Link
                  to={`downloadqr?id=${tran._id}&weight=${tran.weight}&pit=${tran.pit}&farmLocation=${tran.farmLocation}&type=${tran.type}&createdAt=${tran.createdAt}&balerNo=${tran.balerNumber}`}>
                  <FaQrcode className="hover:opacity-60" size={30} />
                </Link>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
