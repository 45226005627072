const { createContext, useState, useEffect } = require("react");

const authContext = createContext({
  login: (token, user) => {},
  token: "",
  isLoggedIn: undefined,
  logout: () => {},
  user: { email: "", name: "", role: "" },
  role: "",
});
function AuthProvider({ children }) {
  const [token, setToken] = useState("");

  const [user, setUser] = useState({});
  const tokenn = localStorage.getItem("token");
  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");

  const login = (token, user) => {
    setUser(user);
    setToken(token);
  };

  useEffect(() => {
    setToken(tokenn);
    setUser({ name: userName, id: userId, role: role, email: email });
  }, [userName, userId, role, tokenn, email]);

  const logout = () => {
    localStorage.clear();
    setToken();
    setUser({});
  };

  return (
    <authContext.Provider
      value={{
        user: user,

        token: token,
        login: login,

        logout: logout,
      }}>
      {children}
    </authContext.Provider>
  );
}
export default AuthProvider;
export { authContext };
