import { Outlet, useNavigation } from "react-router-dom";
import { Footer } from "./components/layouts/Footer";
import HeaderComp from "./components/layouts/header";
import { Loading } from "./components/Loading";
import { useContext, useEffect } from "react";
import { authContext } from "./context/auth";
import moment from "moment";

export function RootLayout() {
  const auth = useContext(authContext);
  const navigation = useNavigation();
  const isLoading = navigation.state === "loading";
  const expiresIn = localStorage.getItem("expiresIn");

  const modExpiresIn = moment(expiresIn);
  const diff = modExpiresIn.diff(moment(new Date()), "milliseconds");

  if (diff < 1) {
    auth.logout();
  }
  useEffect(() => {
    let timer1 = setTimeout(() => {
      auth.logout();
    }, diff);

    return () => {
      clearTimeout(timer1);
    };
  }, [diff, auth.logout]);
  return (
    <div className="flex flex-col">
      <HeaderComp />

      <main className="  m-auto mb-24 md:min-[700px]">
        {isLoading && <Loading />}
        <Outlet />
      </main>

      <Footer />
    </div>
  );
}
