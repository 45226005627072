import logo from "./logo.svg";
import "./App.css";
import { RootLayout } from "./RootLayout";
import { TransactionsPage, loader as TransLoader } from "./pages/Transactions";
import { LoginPage, action as LoginAction } from "./pages/Login";
import { ProductPage } from "./pages/Product";
import { ReportPage } from "./pages/Reports";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthProvider from "./context/auth";
import { ErrorComp } from "./components/Error";
import { UserPage, loader as usersLoader } from "./pages/Users";
import { AddUserPage, action as AddUserAction } from "./pages/NewUser";
import { PrintQrPage } from "./pages/Print";

const browserRoutes = createBrowserRouter([
  {
    element: <RootLayout />,
    path: "/",
    errorElement: <ErrorComp />,
    children: [
      { element: <TransactionsPage />, path: "", loader: TransLoader },
      { element: <LoginPage />, path: "login" },
      { element: <ProductPage />, path: "products" },
      { element: <ReportPage />, path: "reports" },
      { element: <PrintQrPage />, path: "downloadqr" },

      { element: <AddUserPage />, path: "user/add", action: AddUserAction },
      {
        element: <UserPage />,
        path: "users",
        loader: usersLoader,
      },
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={browserRoutes} />
    </AuthProvider>
  );
}

export default App;
