import { useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";

import moment from "moment";
import { FaSave } from "react-icons/fa";

export function ReportGen() {
  const [startDate, setStartDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [endDate, setEndDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [balerNo, setBalerNo] = useState("");

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    const modNewValue = newValue.endDate;
    setEndDate(newValue);
  };

  const handleBaleNumberChange = (event) => {
    setBalerNo(event.target.value);
  };

  return (
    <div
      className="flex  flex-col items-center justify-center md:min-h-[700px] 
      space-y-6  text-center ">
      <h2 className="font-mono mb-5 text-4xl font-bold">Generate report</h2>
      <div className="flex flex-col space-y-2 items-start  text-center">
        <label>Enter start date</label>
        <div>
          <Datepicker
            asSingle={true}
            inputClassName={"md:min-w-[400px]"}
            value={startDate}
            onChange={handleStartDateChange}
            useRange={false}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 items-start  text-center">
        <label>Enter end date</label>
        <div>
          <Datepicker
            asSingle={true}
            inputClassName={"md:min-w-[400px]"}
            value={endDate}
            onChange={handleEndDateChange}
            useRange={false}
          />
        </div>
      </div>

      <div className="flex flex-col space-y-2 items-start  text-center">
        <label>Enter baler number</label>
        <input
          value={balerNo}
          onChange={(event) => handleBaleNumberChange(event)}
          type="Baler Number"
          name="text"
          className={`w-full md:min-w-[400px] p-6 border  rounded-md placeholder:font-sans placeholder:font-light h-6
           border-gray-300 `}
          placeholder="Enter baler number"
        />
      </div>
      <div className="flex items-start   justify-between mt-8 w-full md:min-w-[400px] ">
        <Link
          className="flex items-start justify-start w-full"
          to={`/?startDate=${moment(startDate.startDate).format(
            "YYYY-MM-DD"
          )}&endDate=${moment(endDate.startDate)
            .add(1, "day")
            .format("YYYY-MM-DD")}&balerNumber=${balerNo}`}>
          <FaSave
            className="bg-gray-100 text-gray-600  hover:bg-gray-600 hover:text-gray-100"
            size={40}
          />
        </Link>
      </div>
    </div>
  );
}
