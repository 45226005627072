import { GoAlert } from "react-icons/go";
import HeaderComp from "./layouts/header";
import { Footer } from "./layouts/Footer";
export function ErrorComp() {
  return (
    <div className="flex flex-col h-screen">
      <HeaderComp />

      <main className=" relative z-0 m-auto">
        <div className="flex justify-center items-center space-x-10">
          <GoAlert className="text-red-500 " size={60} />

          <h3 className="text-2xl md:text-3xl font-bold"> An error ocurred</h3>
        </div>
      </main>

      <Footer />
    </div>
  );
}
