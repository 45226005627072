import { FaEdit, faEd } from "react-icons/fa";
import { GoMail, GoVerified } from "react-icons/go";

export function UserItem({ user }) {
  let name = user ? user.email : "";
  if (name.includes("@")) {
    name = name.split("@")[0];

    if (name.length > 13) {
      name = name.slice(0, 12);
    }
  }
  return (
    <div className="flex space space-x-4  bg-gray-200 mx-auto  items-center text-center  hover:opacity-70 transition duration-75 justify-center   shadow-lg md:max-w-[300px] max-h-[100px] md:max-h-[350px] ">
      <div className="w-full h-full max-w-[70px]">
        <img
          src="/images/user.png"
          alt="user"
          className="  bg-cover  w-full h-full"
        />
      </div>

      <div className="flex justify-between items-center  w-3/4  ">
        <div className="flex flex-col space-y-4  justify-center items-start  w-32">
          <div className="flex space-x-2 justify-between items-center">
            <GoMail />
            <h3 className="text-lg text-darkBlue1 font-medium text-ellipsis md:max-w-[100px]  flex-wrap  ">
              {name}
            </h3>
          </div>
          <div className="flex space-x-2 justify-between items-center">
            <GoVerified />
            <h3 className="text-lg text-darkBlue1 font-medium  ">
              {user.role}
            </h3>
          </div>
        </div>

        <div className="mx-4 px-4 ">
          <FaEdit size={30} />
        </div>
      </div>
    </div>
  );
}
