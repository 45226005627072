import { WeightItem } from "../components/Weights/WeighItem";

import Login from "../components/auth/Login";
import { baseUrl } from "../constants";
import * as XLSX from "xlsx/xlsx.mjs";
import { Link, useLoaderData } from "react-router-dom";
import { FaArrowLeft, FaFileDownload } from "react-icons/fa";
import { NoRecords } from "../components/Weights/EmptyRecord";
import moment from "moment";
import { TxTable } from "../components/Weights/Table";

export function TransactionsPage() {
  function downloadExcel(data) {
    const delXl = Date.now();

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, `KLS-${delXl}.xlsx`);
  }
  const data = useLoaderData();

  let transactions = [];

  if (data.result) {
    transactions = data.result[0]["trans"];
  }

  const token = localStorage.getItem("token");

  const transFormedTransactions = transactions.map((tx) => {
    const date = moment(tx.createdAt).format("YYYY-MM-DD");

    const trans = {
      BALIND_DATE: date,
      BALE_NO: tx.balerNumber ?? "_",
      FARM_LOCATION: tx.farmLocation,
      PIT_NAME: tx.pit,
      ITEM_NAME: tx.type,

      WEIGHT_KG: tx.weight,
    };

    return trans;
  });
  return token ? (
    <div className="flex flex-col space-y-4 md:mt-32 h-full md:min-h-[700px] ">
      <div className=" w-full flex justify-between items-center">
        <Link
          className="bg-green-100 text-green-600  flex space-x-3 justify-start items-center rounded-lg w-48 px-4 py-2 font-bold hover:bg-green-600 hover:text-green-100"
          to={"/reports"}>
          <FaArrowLeft />
          <span>Generate report</span>
        </Link>
        {transactions.length > 0 && (
          <FaFileDownload
            onClick={() => downloadExcel(transFormedTransactions)}
            size={30}
            className="bg-green-100 text-green-600 hover:text-green-100 hover:bg-green-600"
          />
        )}
      </div>

      {transactions.length < 1 && <NoRecords />}

      <div className=" flex flex-col space-y-4 md:hidden">
        {transactions.map((tran) => (
          <WeightItem key={tran._id} tx={tran} />
        ))}
      </div>
      <div className="hidden md:block">
        <TxTable trans={transactions} />
      </div>
    </div>
  ) : (
    <Login />
  );
}

export async function loader({ request, params }) {
  const url = new URL(request.url);
  const startDate = url.searchParams.get("startDate");

  const endDate = url.searchParams.get("endDate");
  const baleNumber = url.searchParams.get("balerNumber");

  let path = "txs/txs";
  if (endDate) {
    path = `txs/report?endDate=${endDate}&startDate=${startDate}&balerNumber=${baleNumber}`;
  }

  const response = await fetch(`${baseUrl}${path}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
  }

  return response;
}
