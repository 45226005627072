import { GoAlert } from "react-icons/go";

export function NoRecords() {
  return (
    <div className="flex space-x-10 items-center justify-center md:min-w-[800px] min-w-[360px] my-10">
      <GoAlert size={60} className="text-gray-700 bg-gray-100" />

      <p className="text-gray-700">
        No transactions reported or found, please try another search
      </p>
    </div>
  );
}
