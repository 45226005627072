import { redirect } from "react-router-dom";
import { AddUser } from "../components/auth/AddUserForm";
import { baseUrl } from "../constants";

export function AddUserPage() {
  return <AddUser />;
}

export async function action({ request, params }) {
  const url = new URL(request.url);
  const id = url.searchParams.get("id");
  const data = await request.formData();

  console.log(request.method);

  const response = await fetch(`${baseUrl}auth/add`, {
    method: request.method,

    body: JSON.stringify({
      id: id,
      role: data.get("role"),
      email: data.get("email"),
      password: data.get("password"),
      role: "User",
      name: data.get("name"),
    }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status === 401) {
    return response;
  }

  return redirect("/users");
}
