import {
  Text,
  Document,
  View,
  Page,
  StyleSheet,
  Image,
  balerNo,
} from "@react-pdf/renderer";
const style = StyleSheet.create({
  Page: {
    backgroundColor: "white",

    justifyContent: "center",
    alignItems: "center",
    height: 50,
    width: 200,
  },
  View: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  QRImage: {
    width: "50%",
    height: "50%",
  },
  Text: { color: "#0081C6" },

  logoImage: {
    height: "50%",
    width: "25%",
  },
});

export function QrPdfGen({ id, pit, weight, location, type, balerId }) {
  console.log("called ", id);
  const qrImagePath = document.getElementById(id).toDataURL();

  return (
    <Document>
      <Page size={"B8"} style={style.Page} orientation="portrait">
        <View
          style={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Image allowDangerousPaths src={"/images/feed.png"} />

          <View style={style.View}>
            <Image
              style={style.QRImage}
              allowDangerousPaths
              src={qrImagePath}
            />
            <View>
              <Text style={{ fontWeight: "black", fontSize: 20 }}>
                {weight} Kg
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              fontSize: 10,
            }}>
            <Text>{type}</Text>
            <Text>{`#ticket${balerId}-${id}`}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
