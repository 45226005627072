import { Table } from "flowbite-react";
import moment from "moment/moment";
import { Link } from "react-router-dom";

export function UserTable({ users }) {
  return (
    <Table>
      <Table.Head>
        <Table.HeadCell>User Id</Table.HeadCell>
        <Table.HeadCell>Name</Table.HeadCell>
        <Table.HeadCell>Email</Table.HeadCell>
        <Table.HeadCell>Role</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Added On</Table.HeadCell>

        <Table.HeadCell>
          <span className="sr-only">Edit</span>
        </Table.HeadCell>
      </Table.Head>

      <Table.Body className="divide-y">
        {users.map((user) => {
          const date = moment(user.createdAt).format("DD-MM-YYYY");
          const modOn = moment(user.updatedAt).format("DD-MM-YYYY");

          return (
            <Table.Row
              key={user._id}
              className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>{user._id}</Table.Cell>
              <Table.Cell>{user.name}</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {user.role}
              </Table.Cell>
              <Table.Cell>{user.status}</Table.Cell>
              <Table.Cell>{date}</Table.Cell>
              <Table.Cell>
                <Link
                  to={`/user/add?name=${user.name}&id=${user._id}&role=${user.role}&email=${user.email}`}>
                  <span className="text-lg font-bold text-green-400 hover:text-green-950">
                    EDIT
                  </span>
                </Link>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
